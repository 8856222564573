<template>
  <div class="">
    <Header class="header" />
    <Navigation class="navigation" />
  </div>
</template>

<script>
export default {
  name: "AppTopbar",
  components: {
    Header: () => import("./topbar-header.vue"),
    Navigation: () => import("./topbar-navigation.vue"),
  },
  data() {
    return {
      classFixed: "fixed-onscroll",
    };
  },
  // fixed navigation when scroll
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const nav = document.querySelector(".navigation");
      const navHeight = nav.offsetHeight;

      const header = document.querySelector(".header");
      const headerHeight = header.offsetHeight;

      if (window.pageYOffset > headerHeight) {
        nav.classList.add(this.classFixed);
        header.style.marginBottom = `${navHeight}px`;
      } else {
        nav.classList.remove(this.classFixed);
        header.style.marginBottom = "0";
      }
    },
  },
};
</script>

<style lang="scss">
.navigation {
  transition: all 1s ease-in-out;
  &.fixed-onscroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
}
</style>
